exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ancientgram-js": () => import("./../../../src/pages/ancientgram.js" /* webpackChunkName: "component---src-pages-ancientgram-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-posca-js": () => import("./../../../src/pages/posca.js" /* webpackChunkName: "component---src-pages-posca-js" */),
  "component---src-pages-sekanjabin-js": () => import("./../../../src/pages/sekanjabin.js" /* webpackChunkName: "component---src-pages-sekanjabin-js" */),
  "component---src-pages-store-locator-js": () => import("./../../../src/pages/store-locator.js" /* webpackChunkName: "component---src-pages-store-locator-js" */),
  "component---src-pages-switchel-js": () => import("./../../../src/pages/switchel.js" /* webpackChunkName: "component---src-pages-switchel-js" */)
}

